export const SERVER_BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? "http://localhost:4041"
    : process.env.REACT_APP_ENV === "test"
    ? "http://192.168.8.100:4041"
    : process.env.REACT_APP_API_URL;

// console.log(process.env.NODE_ENV, SERVER_BASE_URL);
export const URLS = {
  mainSite: process.env.REACT_APP_MAINSITE_FRONTEND,
  mainSiteBackend: process.env.REACT_APP_MAINSITE_BACKEND,

  landingPage: "",
  dashboard: "/dashboard",
  clients: "/dashboard/clients",
  activities: "/dashboard/activities",
  employees: "/dashboard/employees",
  employeeDashboard: "/user/employee-dashboard",
  holidays: "/dashboard/holidays",
  leaves: "/dashboard/leaves",
  leavesEmployee: "/dashboard/leaves-employee",
  leaveSettings: "/dashboard/leaveSettings",
  attendance: "/dashboard/attendance",
  attendanceEmployee: "/dashboard/attendance-employee",
  departments: "/dashboard/departments",
  designations: "/dashboard/designations",
  performance: "/dashboard/performance",
  timesheet: "/dashboard/timesheet",
  promotion: "/dashboard/promotion",
  resignation: "/dashboard/resignation",
  termination: "/dashboard/termination",
  overtime: "/dashboard/allowance-application",
  projects: "/dashboard/projects",
  approvals: "/dashboard/approvals",
  teams: "/dashboard/teams",
  projectView: "/dashboard/project-view",
  contracts: "/dashboard/contracts",
  contractView: "/dashboard/contract-view",
  teamView: "/dashboard/team-view",
  tasks: "/dashboard/tasks",
  voiceCall: "/dashboard/voice-call",
  videoCall: "/dashboard/video-call",
  outgoingCall: "/dashboard/outgoing-call",
  incomingCall: "/dashboard/incoming-call",
  contacts: "/dashboard/contacts",
  leads: "/dashboard/leads",
  estimates: "/dashboard/estimates",
  invoices: "/dashboard/invoices",
  payments: "/dashboard/payments",
  expenses: "/dashboard/expenses",
  providentFund: "/dashboard/provident-fund",
  taxes: "/dashboard/taxes",
  salary: "/dashboard/salary",
  salaryView: "/dashboard/salary-view",
  payrollItems: "/dashboard/payroll-items",
  jobs: "/dashboard/jobs",
  jobDetail: "/dashboard/job-details",
  jobList: "/job-list",
  jobView: "/job-view",
  jobApplicants: "/dashboard/job-applicants",
  tickets: "/dashboard/tickets",
  events: "/dashboard/events",
  inbox: "/dashboard/inbox",
  chat: "/dashboard/chat",
  assets: "/dashboard/assets",
  policies: "/dashboard/policies",
  users: "/dashboard/users",
  expenseReports: "/dashboard/expense-reports",
  invoiceReports: "/dashboard/invoice-reports",
  reports: "/dashboard/invoice-reports",
  settings: "/dashboard/settings",
  login: "/login",
  register: "/dashboard/register",
  forgotPassword: "/dashboard/forgot-password",
  otp: "/dashboard/otp",
  lockScreen: "/dashboard/lock-screen",
  profile: "/dashboard/profile",
  clientProfile: "/dashboard/client-profile",
  error404: "/error-404",
  error500: "/dashboard/error-500",
  blankPage: "/dashboard/blank-page",
  components: "/dashboard/components",

  deductionSettings: "/dashboard/deduction-settings/:name",
  createSalary: "/dashboard/process-payroll",
  jobGrade: "/dashboard/job-grade",
  deductionReports: "/dashboard/deduction-reports",
  payHistory: "/dashboard/pay-history",
  paySettings: "/dashboard/pay-settings",
  salarySummary: "/dashboard/salary-summary",
  loan: "/dashboard/loan",
  timing: "/dashboard/timing",
  bonusSettings: "/dashboard/bonus-settings/:name",
  allowance: "/dashboard/allowance/:name",
  benefit: "/dashboard/benefit/:name",

  bonusReport: "/dashboard/bonus-reports",
  allowanceReport: "/dashboard/allowance-reports",
  benefitReport: "/dashboard/benefit-reports",
  permmission: "/dashboard/permission",

  userDashboardBase: "/user",
  userDashboard: "/user/home",
  userDashboardProfile: "/user/profile",
  userDashboardProjects: "/user/projects",
  userDashboardProjectView: "/user/project-view",
  userDashboardTasks: "/user/tasks",
  userDashboardPerformance: "/user/performance",
  userDashboardPolicy: "/user/policy",
  userDashboardLeaves: "/user/leaves",
  userDashboardTeams: "/user/teams",
  userDashboardTeamView: "/user/team-view",
  userDashboardContracts: "/user/contracts",
  userDashboardLoan: "/user/loan",
  userDashboardAllowance: "/user/allowance",

  editEmployeeJobGrade: "/dashboard/change-job-grade",
  earnedAllowance: "/dashboard/overtime",

  backendStatic: `${SERVER_BASE_URL}/public/static`,
  backendEmployees: `${SERVER_BASE_URL}/api/employees`,
  backendUsers: `${SERVER_BASE_URL}/api/users`,
  backendJobGrades: `${SERVER_BASE_URL}/api/jobGrades`,
  backendLocations: `${SERVER_BASE_URL}/api/locations`,
  backendDepartments: `${SERVER_BASE_URL}/api/Departments`,
  backendSkills: `${SERVER_BASE_URL}/api/Skills`,
  backendTrainings: `${SERVER_BASE_URL}/api/Trainings`,
  backendLifeInsurances: `${SERVER_BASE_URL}/api/lifeinsurances`,
  backendEmployeeHMOs: `${SERVER_BASE_URL}/api/employeehmos`,
  backendProjects: `${SERVER_BASE_URL}/api/projects`,
  backendContracts: `${SERVER_BASE_URL}/api/contracts`,
  backendClients: `${SERVER_BASE_URL}/api/clients`,
  backendTasks: `${SERVER_BASE_URL}/api/tasks`,
  backendDesignations: `${SERVER_BASE_URL}/api/designations`,
  backendJobs: `${SERVER_BASE_URL}/api/jobs`,
  backendApplications: `${SERVER_BASE_URL}/api/applications`,
  backendPolicies: `${SERVER_BASE_URL}/api/policies`,
  backendLeaves: `${SERVER_BASE_URL}/api/leaves`,
  backendApplyLeaves: `${SERVER_BASE_URL}/api/applyleaves`,
  backendLeaveBalances: `${SERVER_BASE_URL}/api/leaveBalances`,
  backendLeaveCustomPolicies: `${SERVER_BASE_URL}/api/leaveCustomPolicies`,
  backendTeams: `${SERVER_BASE_URL}/api/teams`,
  backendCompanies: `${SERVER_BASE_URL}/api/companies`,
  backendNotifications: `${SERVER_BASE_URL}/api/notifications`,
  backendLoan: `${SERVER_BASE_URL}/api/loan`,
  backendBranch: `${SERVER_BASE_URL}/api/branch`,
  backendBonusSettings: `${SERVER_BASE_URL}/api/bonusSettings`,
  backendAllowance: `${SERVER_BASE_URL}/api/allowance`,
  backendBenefit: `${SERVER_BASE_URL}/api/benefit`,

  backendDeductionSettings: `${SERVER_BASE_URL}/api/deductionSettings`,
  backendPayroll: `${SERVER_BASE_URL}/api/payroll`,
  backendCompanySettings: `${SERVER_BASE_URL}/api/settings/company-settings`,
  serverBaseUrl: SERVER_BASE_URL,
  backendApp: `${SERVER_BASE_URL}/api/app`,
  backendPromotion: `${SERVER_BASE_URL}/api/promotion`,
  backendResignation: `${SERVER_BASE_URL}/api/resignation`,
  backendTermination: `${SERVER_BASE_URL}/api/termination`,
  backendJobGradeHistory: `${SERVER_BASE_URL}/api/jobGradeHistory`,
  backendSalary: `${SERVER_BASE_URL}/api/salary`,
  backendOvertime: `${SERVER_BASE_URL}/api/overtime`,

  backendemployeeSalary: `${SERVER_BASE_URL}/api/employeeSalary`,
  backendsalarySetting: `${SERVER_BASE_URL}/api/salarySetting`,
  backendattendance: `${SERVER_BASE_URL}/api/attendance`,
  backendpermmission: `${SERVER_BASE_URL}/api/permission`,
};
